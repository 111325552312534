import classNames from 'classnames'
import styled from 'styled-components'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import HeartFillIcon from 'remixicon-react/HeartFillIcon'
import ArrowLeftRightLineIcon from 'remixicon-react/ArrowLeftRightLineIcon'

import {styledTheme} from '@festi/common/themes'
import {useWishlist} from '@festi/common/utils/wishlists'
import {useAuth, useComparison} from '@festi/common/contexts'
import {
  VariantList,
  VariantDetail,
  WishlistVariant,
} from '@festi/common/api/rest'
import {MainButton, MainIconButton} from '@festi/common/components/buttons'

const UtilityWrapper = styled.div`
  display: flex;
  gap: 2px;

  &.horizontal {
    gap: 4px;
    width: 100%;
  }
`

interface ActionButtonsProps {
  variant: VariantList | VariantDetail | WishlistVariant
  horizontal?: boolean
  disableWishlist?: boolean
}

export default function ProductActionButtons({
  variant,
  horizontal,
  disableWishlist,
}: ActionButtonsProps) {
  const {user} = useAuth()
  const {isVariantInWishlist, toggleVariantInWishlist} = useWishlist()
  const {comparedSkus, addToComparison, removeFromComparison} = useComparison()

  const inComparison = comparedSkus?.indexOf(variant.sku) !== -1

  return (
    <UtilityWrapper className={classNames({horizontal})}>
      {horizontal ? (
        <>
          {!disableWishlist && user?.id && (
            <MainButton
              label="Óskalisti"
              suffixIcon={
                isVariantInWishlist(variant.sku) ? HeartFillIcon : HeartLineIcon
              }
              iconColor={
                isVariantInWishlist(variant.sku)
                  ? styledTheme.palette.lightBlue
                  : styledTheme.palette.ui30Solid
              }
              size="medium"
              buttonVariant="ghost"
              onClick={() => toggleVariantInWishlist(variant)}
              fullWidth
            />
          )}
          <MainButton
            label="Bera saman"
            size="medium"
            buttonVariant="ghost"
            suffixIcon={ArrowLeftRightLineIcon}
            iconColor={
              inComparison
                ? styledTheme.palette.lightBlue
                : styledTheme.palette.ui30Solid
            }
            onClick={
              inComparison
                ? () => removeFromComparison(variant.sku)
                : () => addToComparison(variant)
            }
            fullWidth
          />
        </>
      ) : (
        <>
          {!disableWishlist && user?.id && (
            <MainIconButton
              icon={
                isVariantInWishlist(variant.sku) ? HeartFillIcon : HeartLineIcon
              }
              iconColor={
                isVariantInWishlist(variant.sku)
                  ? styledTheme.palette.lightBlue
                  : styledTheme.palette.ui30Solid
              }
              buttonVariant="ghostNegative"
              onClick={() => toggleVariantInWishlist(variant)}
            />
          )}
          <MainIconButton
            icon={ArrowLeftRightLineIcon}
            iconColor={
              inComparison
                ? styledTheme.palette.lightBlue
                : styledTheme.palette.ui30Solid
            }
            buttonVariant="ghostNegative"
            onClick={
              inComparison
                ? () => removeFromComparison(variant.sku)
                : () => addToComparison(variant)
            }
          />
        </>
      )}
    </UtilityWrapper>
  )
}
