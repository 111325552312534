import React from 'react'

import {media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import classNames from 'classnames'
import {palette} from 'styled-tools'

import {P} from '@festi/common/components/typography'
import {Variant, VariantDetail, VariantList} from '@festi/common/api/rest'
import {Tooltip} from '@festi/common/components/common'
import {onDutyFree} from '@festi/common/constants/channels'
import {getDiscount} from '@festi/common/utils/checkout'
import {AugmentedUserPrice} from '@festi/common/utils/rest'

const BannersContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  transform: scale(0.8);
  transform-origin: bottom right;

  &.onProductPage {
    transform-origin: top right;
    bottom: unset;
    top: 0px;
  }

  &.hasEnergyLabel {
    top: 60px;
  }

  ${media.md`
    transform: scale(1);

    &.small {
      transform: scale(0.8);
    }

    &.hasEnergyLabel {
      top: 70px;
    }
  `}
`

const WarrantyBannersContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  transform: scale(0.8);
  transform-origin: bottom left;

  &.onProductPage {
    bottom: 42px;
  }

  ${media.md`
    transform: scale(1);

    &.small {
      transform: scale(0.8);
    }

    &.onProductPage {
      bottom: 120px;
    }
  `}
`

const BannerImage = styled.img``

const DiscountText = styled(P)`
  position: absolute;
  color: ${palette('black')};
  font-family: 'ElkjopHeadline', sans-serif;
  font-size: 32px;
  text-align: center;
  letter-spacing: -0.03em;
`

const DiscountBannerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Banner {
  content: React.ReactNode
  tooltip?: string
}

interface Props {
  small?: boolean
  variant: VariantDetail | VariantList | Variant
  userPrice?: AugmentedUserPrice
  onProductPage?: boolean
  hasEnergyLabel?: boolean
}

export default function ItemBanners({
  small = false,
  variant,
  userPrice,
  onProductPage = false,
  hasEnergyLabel = false,
}: Props) {
  const isBlackFriday = !onDutyFree && variant.banner === 'Svartur fössari'
  const isPresale = !onDutyFree && variant.banner === 'Forsala'
  const isBirthdayOffer = !onDutyFree && variant.banner === 'Afmælistilboð'
  const isPinkOctober = !onDutyFree && variant.banner === 'Bleikur október'

  const isThreeYearWarranty = variant.warrantyYears === 3
  const isFiveYearWarranty = variant.warrantyYears === 5
  const isSevenYearWarranty = variant.warrantyYears === 7

  const variantDiscount = getDiscount(variant)
  const discount = userPrice?.discount || variantDiscount

  const banners: Banner[] = [
    discount && {
      content: (
        <DiscountBannerWrapper>
          <BannerImage
            alt="Afsláttur"
            src="/item-banners/discount-banner.svg"
          />
          <DiscountText>&ndash; {Math.round(discount * 100)}%</DiscountText>
        </DiscountBannerWrapper>
      ),
    },
    isPresale && {
      content: (
        <BannerImage alt="Forsala" src="/item-banners/presale-banner.svg" />
      ),
    },
    isBirthdayOffer && {
      content: (
        <BannerImage
          alt="Afmælistilboð"
          src="/item-banners/birthday-offer-banner.svg"
        />
      ),
    },
    isPinkOctober && {
      content: (
        <BannerImage
          alt="Bleikur október"
          src="/item-banners/pink-october-banner.svg"
        />
      ),
      tooltip:
        'Elko gefur 10% af söluverði þessarar vöru í krabbameinsrannsóknir',
    },
    isBlackFriday && {
      content: (
        <BannerImage
          alt="Svartur fössari"
          src="/item-banners/black-friday-banner.svg"
        />
      ),
    },
  ]

  const warrantyBanners: Banner[] = [
    isThreeYearWarranty && {
      content: (
        <BannerImage
          alt="3 ára ábyrgð"
          src="/item-banners/three-year-warranty-banner.svg"
        />
      ),
    },
    isFiveYearWarranty && {
      content: (
        <BannerImage
          alt="5 ára ábyrgð"
          src="/item-banners/five-year-warranty-banner.svg"
        />
      ),
    },
    isSevenYearWarranty && {
      content: (
        <BannerImage
          alt="7 ára ábyrgð"
          src="/item-banners/seven-year-warranty-banner.svg"
        />
      ),
    },
  ]

  return (
    <>
      <BannersContainer
        className={classNames({onProductPage, hasEnergyLabel, small})}
      >
        {banners?.map(
          (banner, i) =>
            !!banner &&
            (banner.tooltip ? (
              <Tooltip id={`item-banner-${i}`} key={i} content={banner.tooltip}>
                <React.Fragment>{banner.content}</React.Fragment>
              </Tooltip>
            ) : (
              <React.Fragment key={i}>{banner.content}</React.Fragment>
            )),
        )}
      </BannersContainer>
      <WarrantyBannersContainer className={classNames({onProductPage, small})}>
        {warrantyBanners?.map(
          (banner, i) =>
            !!banner && (
              <React.Fragment key={i}>{banner.content}</React.Fragment>
            ),
        )}
      </WarrantyBannersContainer>
    </>
  )
}
