import {useCallback, useState} from 'react'

import classNames from 'classnames'
import styled from 'styled-components'

import WebpImage from 'src/components/common/WebpImage'

const ImageWrapper = styled.div`
  width: 100%;
  height: 140px;
  max-width: 420px;
  margin: auto;

  &.compact {
    min-height: unset;
    padding: unset;
  }

  &.large {
    height: 260px;
  }
`

interface ImageProps {
  src: string
  alt: string
  jpg: string
  large?: boolean
  compact?: boolean
}

export default function ProductImage({
  src,
  alt,
  jpg,
  large,
  compact = false,
}: ImageProps): JSX.Element {
  const [img, setImg] = useState('')

  const onError = useCallback(() => {
    setImg('/images/placeholder.png')
  }, [])

  return (
    <ImageWrapper className={classNames({compact, large})}>
      <WebpImage webp={img || src} jpg={jpg} alt={alt} onError={onError} />
    </ImageWrapper>
  )
}
