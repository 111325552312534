import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import {Image as DatoImage} from 'react-datocms'
import {palette} from 'styled-tools'

import {Category} from '@festi/common/api/rest'
import {fluidRange} from '@festi/common/utils/styles'
import {Page_page_content_GridSystemRecord_card_CategoryRecord_image_responsiveImage} from '@festi/common/api/datocms/types/Page'

import WebpImage from 'src/components/common/WebpImage'

const Container = styled(Link)`
  display: flex;
  justify-content: center;
  ${fluidRange('height', '128px', '200px')};
  width: 100%;
  border-radius: 12px;
  border: 1px solid transparent;
  background-color: ${palette('white')};
  overflow: hidden;

  &:hover {
    border-color: ${palette('ui20Solid')};
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
  width: 100%;
  height: 100%;
  ${fluidRange('padding', '16px', '20px')};
`

const Title = styled.div`
  flex-grow: 1;
  width: 100%;
  font-family: 'ElkjopSans', sans-serif;
  text-align: center;
  ${fluidRange('font-size', '14px', '18px')};
  ${fluidRange('line-height', '20px', '24px')};
  font-weight: 500;
  color: ${palette('blue')};

  @media (max-width: 330px) {
    font-size: 13.5px;
  }
`

const CategoryDatoImage = styled(DatoImage)`
  align-self: flex-end;
  max-width: 220px;
  max-height: 130px;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-height: 90px;
  }
`

const Image = styled.div`
  align-self: center;
  max-height: calc(100% - 30px);

  @media (max-width: 768px) {
    max-height: calc(100% - 30px);
  }
`

interface Props {
  category: Category
  image?: Page_page_content_GridSystemRecord_card_CategoryRecord_image_responsiveImage | null
  slug: string | null
}

export default function CategoryCard({
  category,
  image,
  slug,
}: Props): JSX.Element {
  return (
    <Container href={`/voruflokkar/${slug}`}>
      <Inner>
        <Title>{category?.name}</Title>
        {image ? (
          <CategoryDatoImage
            data={image}
            objectFit="contain"
            objectPosition="center"
          />
        ) : (
          <Image>
            <WebpImage
              webp={category.backgroundImage.webpXs}
              jpg={category.backgroundImage.jpgXs}
              alt={category.name}
            />
          </Image>
        )}
      </Inner>
    </Container>
  )
}
